import React, { useEffect, useRef, useState, useMemo } from 'react';
import moment from 'moment';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import useReactRouter from 'use-react-router';
import gql from 'graphql-tag';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ViewListIcon from '@material-ui/icons/ViewList';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { FormControlLabel, InputLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { MTableToolbar } from 'material-table';
import useLocalStorage from 'react-use-localstorage';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SyncIcon from '@material-ui/icons/Sync';
import { CircularProgress } from '@material-ui/core';

import { useMe } from '../../reducers/me';
import Table from '../../components/Table';
import BuildingModel from '../../model/building';
import { TelegramsDialog } from '../../components/TelegramsDialog';
import { WhitelistDialog } from '../../components/WhitelistDialog';
import GatewayModel from '../../model/gateway';
import useSearchQuery from '../../lib/search-query';
import getSignalStrengthLabel from '../../lib/rssi-helper';
import data from '../../lib/data';

const GATEWAY_SEARCH = gql`
  mutation gatewaySearch(
    $query: SearchQuery!
    $gatewayQuery: GatewaySearchQuery
  ) {
    gatewaySearch(
      query: $query
      gatewayQuery: $gatewayQuery
    ) {
      rows {
        id
        iccid
        imei
        serial_number
        comment
        manufacturer
        model
        last_reading
        file_prefix
        is_backpack
        organization {
          id
        }
        building {
          id
          external_id
          street
          house_number
          city
          postalcode
        }
        supervision {
          id
          imei
          battery
          rssi
          operator
        }
        gateway_stats {
          status
          last_data_sending
        }
      }
      count
    }
  }
`;

const DELETE_GATEWAY = gql`
  mutation deleteGateway($id: ID!) {
    deleteGateway(id: $id) {
      id
    }
  }
`;

const AUTOASSIGN_GATEWAY_BUILDING = gql`
  mutation autoassignGatewayBuilding($id: ID!) {
    autoassignGatewayBuilding(id: $id)
  }
`;

const AUTOASSIGN_ALL_GATEWAYS = gql`
  mutation autoassignAllGateways {
    autoassignAllGateways
  }
`;

const SYNC_GATEWAYS = gql`
  mutation syncGateways {
    syncGateways
  }
`;

export const renderBuildingAddress = (rowData) => {
    if (!rowData.building) {
        return '';
    }

    const {
        building: { street, house_number, city, postalcode },
    } = rowData;

    return BuildingModel.fullAddress(street, house_number, city, postalcode);
};

const renderTimeFromLastSending = (date) => {
    return date ? moment(date).fromNow() : date; //'Never',
};

const useStyles = makeStyles((theme) => ({
    tableToolbarWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    filterBox: {
        alignSelf: 'start',
    },
    radioRed: {
        '&$radioChecked': {
            color: 'red',
        },
    },
    radioYellow: {
        '&$radioChecked': {
            color: '#FFAA00',
        },
    },
    radioGreen: {
        '&$radioChecked': {
            color: 'green',
        },
    },
    radioGray: {
        '&$radioChecked': {
            color: 'gray',
        },
    },
    labelRed: {
        color: 'red',
    },
    labelYellow: {
        color: '#FFAA00',
    },
    labelGreen: {
        color: 'green',
    },
    gatewayTypeControl: {
        color: '#3075BB',
    },
    radioChecked: {},
}));

const filterUnknownGateways = (gateways, searchText) => {
    return gateways.filter((gateway) => {
        if (gateway.organization?.id) {
            // include all gateways that have organization
            return true;
        } else if (gateway.serial_number === searchText) {
            // include gateways with unknown organization only
            // if search text matches gateway serial number
            return true;
        }
        return false;
    });
};

const WHITELIST_MANUFACTURERS = new Set(['webdyn', 'engelmann']);

export default function GatewaysInOperation({ match }) {
    const [gatewaySearch] = useMutation(GATEWAY_SEARCH);
    const [pageSize, setPageSize] = useState(10);
  
    const query = useSearchQuery();
    const { t } = useTranslation(['gateway']);
    const [useMeData] = useMe();
    // console.log(useMeData);
    const [gatewayStatusFilter, setGatewayStatusFilter] = useLocalStorage(
      'gateway-status-filter',
      null,
    );
    const [gatewayTypeFilter, setGatewayTypeFilter] = useLocalStorage(
      'gateway-type-filter',
      null,
    );
    const classes = useStyles();
    const { history } = useReactRouter();
    const [deleteGateway] = useMutation(DELETE_GATEWAY);
    const [
      autoassignGatewayBuilding,
      { loading: autoassignGatewayLoading, error: autoassignGatewayError },
    ] = useMutation(AUTOASSIGN_GATEWAY_BUILDING);
    const [
      autoassignAllGateways,
      { loading: autoassignAllGatewaysLoading, error: autoassignAllGatewaysError },
    ] = useMutation(AUTOASSIGN_ALL_GATEWAYS);
    const [syncGateways] = useMutation(SYNC_GATEWAYS);
  
    const [chosenGateway, setChosenGateway] = useState(null);
    const [chosenWhitelistGateway, setChosenWhitelistGateway] = useState(null);
    const [refreshInProgress, setRefreshInProgress] = useState(false);
    const refreshInProgressTimoutRef = useRef(null);
  
    useEffect(() => {
      return () => {
        // clear timeout when unmounting
        clearTimeout(refreshInProgressTimoutRef.current);
      };
    });
    const tableRef = useRef();
    useEffect(() => {
      tableRef.current && tableRef.current.onQueryChange();
    }, [gatewayStatusFilter, gatewayTypeFilter]);
  
    const [searchTextChanged, setSearchTextChanged] = useState(null);
    // to prevent remounting toolbar after entering something into search field
    const Toolbar = useMemo(() => ((props) => (
      <div className={classes.tableToolbarWrapper}>
        <Box ml={2} pt={2} className={classes.filterBox}>
          <InputLabel>{t('gateway:Gateway status')}</InputLabel>
          <RadioGroup
            value={gatewayStatusFilter}
            onChange={({ currentTarget: { value } }) => {
              setGatewayStatusFilter(value);
            }}
          >
            {GatewayModel.GATEWAY_STAT_STATUSES.map((status) => {
              const value = GatewayModel.GATEWAY_STAT_STATUS_VALUES[status];
              const color = GatewayModel.GATEWAY_STAT_STATUS_COLORS[status];
              return <FormControlLabel
                onClick={() => {
                  if (gatewayStatusFilter === value) {
                    setGatewayStatusFilter(null);
                  }
                }}
                key={value}
                control={
                  <Radio classes={{
                    root: classes[`radio${color}`],
                    checked: classes.radioChecked,
                  }} />
                }
                value={value}
                label={t(`gateway:${value}`)}
                className={classes[`label${color}`]}
              />
            })}
          </RadioGroup>
        </Box>
        <Box ml={2} pt={2} className={classes.filterBox}>
          <InputLabel>{t('gateway:Gateway type')}</InputLabel>
          <RadioGroup
            value={gatewayTypeFilter}
            onChange={({ currentTarget: { value } }) => {
              setGatewayTypeFilter(value);
            }}
          >
            <FormControlLabel
              onClick={() => {
                if (gatewayTypeFilter === 'virtual_gateways') {
                  setGatewayTypeFilter(null);
                }
              }}
              control={<Radio color='primary' />}
              value={'virtual_gateways'}
              label={t(`gateway:Platform Data`)}
              className={classes.gatewayTypeControl}
            />
            <FormControlLabel
              onClick={() => {
                if (gatewayTypeFilter === 'physical_gateways') {
                  setGatewayTypeFilter(null);
                }
              }}
              control={<Radio color='primary' />}
              value={'physical_gateways'}
              label={t(`gateway:Gateways sending directly`)}
              className={classes.gatewayTypeControl}
            />
          </RadioGroup>
        </Box>
        <MTableToolbar {...props} />
      </div>
    )), [
      gatewayStatusFilter,
      gatewayTypeFilter,
    ]);
  
    if (autoassignGatewayError || autoassignAllGatewaysError) {
      return <div>Error</div>;
    }
  
    if (refreshInProgress) {
      return <CircularProgress></CircularProgress>;
    }
  
    const onTelegramsDialogClose = () => setChosenGateway(null);
    const onWhitelistDialogClose = () => setChosenWhitelistGateway(null);
  
    const columns = [
      {
        title: t('gateway:ID'),
        field: 'id',
        type: 'numeric',
        defaultSort: 'desc',
        editable: 'never',
      },
      { title: t('Serial Number'), field: 'serial_number' },
      { title: t('IMEI'), field: 'imei' },
      { title: t('ICCID'), field: 'iccid' },
      { title: t('Manufacturer'), field: 'manufacturer' },
      { title: t('Model'), field: 'model' },
      {
        title: t('Last Reading'),
        field: 'last_reading',
        type: 'datetime',
        render: (rowData) => renderTimeFromLastSending(rowData.last_reading),
      },
      {
        title: t('Last Sending'),
        field: 'gateway_stats.last_data_sending',
        type: 'datetime',
        editable: 'never',
        filter: {
          fields: ['$GatewayStats.last_data_sending$'],
        },
        association: 'GatewayStats',
        render: (rowData) => renderTimeFromLastSending(rowData.gateway_stats?.last_data_sending),
      },
      {
        title: t('Building ID1'),
        field: 'building.external_id',
        filter: {
          fields: ['$Building.external_id$'],
        },
        association: 'Building',
      },
      {
        title: t('Building Address'),
        field: 'buildingAddress',
      },
      { title: t('Comment'), field: 'comment' },
      {
        title: t('Battery'),
        field: 'supervision.battery',
        filter: {
          fields: ['$GatewayStats.LatestSupervision.battery$'],
        },
        association: 'GatewayStats.LatestSupervision',
      },
      {
        title: t('RSSI'),
        field: 'supervision.rssi',
        filter: {
          fields: ['$GatewayStats.LatestSupervision.rssi$'],
        },
        association: 'GatewayStats.LatestSupervision',
        render: (rowData) => {
          const rssi = rowData?.supervision?.rssi;
          if (!rssi)
            return;
          const label = t(`rssi:${getSignalStrengthLabel(rssi)}`)
          return `${label} (${rssi} dBm)`;
        },
      },
      {
        title: t('Operator'),
        field: 'supervision.operator',
        filter: {
          fields: ['$GatewayStats.LatestSupervision.operator$'],
        },
        association: 'GatewayStats.LatestSupervision',
      },
      {
        title: t('Status'),
        field: 'gateway_stats.status',
        filter: {
          fields: ['$GatewayStats.status$'],
        },
        association: 'GatewayStats',
        render: (rowData) => {
          const status = rowData?.gateway_stats?.status;
          return (
            <Typography align='center'>
              <Radio
                disabled={true}
                checked={true}
                classes={{
                  ...(status && { root: classes[`radio${GatewayModel.GATEWAY_STAT_STATUS_COLORS[status]}`] }),
                  checked: classes.radioChecked,
                }}
              />
              {GatewayModel.GATEWAY_STAT_STATUS_VALUES[status]}
            </Typography>
          );
        },
      },
      {
        title: t('gateway:File prefix'),
        field: 'file_prefix',
      },
    ];
    const columnFieldToColumnMap = columns.reduce(
      (result, column) => ({ ...result, [column.field]: column }),
      {},
    );
    return (
      <React.Fragment>
        {chosenGateway ? (
          <TelegramsDialog
            gateway={chosenGateway}
            onClose={onTelegramsDialogClose}
          />
        ) : null}
        {chosenWhitelistGateway ? (
        <WhitelistDialog
          gateway={chosenWhitelistGateway}
          onClose={onWhitelistDialogClose}
        />
      ) : null}
        <Table
          tableId="gateways"
          title=""
          tableRef={tableRef}
          isLoading={autoassignGatewayLoading || autoassignAllGatewaysLoading}
          columns={columns}
          data={data(
            columnFieldToColumnMap,
            gatewaySearch,
            (response) => {
              // console.log(response.data.gatewaySearch);
              // search text needs to be extracted directly from table state
              // because search field can have saved search text that will remain after
              // page reloading, onSearchChange can't be used for this because it triggers
              // only when user changes something in search field so it won't be triggered
              // after page is loaded with saved serial number
              const tableSearchText = tableRef.current?.state.searchText;
              const gateways = filterUnknownGateways(response.data.gatewaySearch.rows, tableSearchText);
              const rows = gateways.map((gateway) => {
                return {
                  ...gateway,
                  buildingAddress: renderBuildingAddress(gateway),
                };
              })
              return { ...response.data.gatewaySearch, rows };
            },
            setPageSize,
            [],
            false,
            {
              gatewayQuery: {
                status: GatewayModel.getGatewayStatusFromValue(gatewayStatusFilter),
                type: gatewayTypeFilter === 'null' ? null : gatewayTypeFilter,
                organizationId: useMeData.organizationId ? useMeData.organizationId : null,
                includeUnknownGateways: true,
              }
            }
          )}
          onSearchChange={(searchedText) => {
            // state change is needed to update unknown
            // organization gateways that match search text
            setSearchTextChanged(searchedText);
          }}
          options={{
            sorting: true,
            pageSize,
            searchAutoFocus: true,
            columnsButton: true,
            compressed: true,
          }}
          components={{
            Toolbar,
          }}
  
          onRowClick={(event, rowData) => {
            history.push(`${match.path}/${rowData.id}`);
          }}
          editable={{
            onRowDelete: async (oldData) => {
              await deleteGateway({
                variables: {
                  id: oldData.id,
                },
              });
            },
          }}
          actions={[
            {
              icon: AddBoxIcon,
              tooltip: 'Add',
              isFreeAction: true,
              onClick: () => {
                history.push(`${match.path}/new`);
              },
            },
            {
              icon: LocationCityIcon,
              tooltip: t('gateway:Start automatic building assignment'),
              isFreeAction: true,
              onClick: async () => {
                await autoassignAllGateways();
              },
            },
            {
              icon: SyncIcon,
              tooltip: t('gateway:Sync gateways'),
              isFreeAction: true,
              onClick: async () => {
                await syncGateways();
                setRefreshInProgress(true);
                refreshInProgressTimoutRef.current = setTimeout(() => {
                  window.location.reload();
                }, 4000);
              },
            },
            (rowData) => ({
              icon: ViewListIcon,
              tooltip: t('gateway:Show telegrams'),
              isFreeAction: false,
              onClick: () => {
                setChosenGateway(rowData);
              },
              disabled: !fp.get('last_reading', rowData),
            }),
            (rowData) => ({
              icon: ListAltIcon,
              tooltip: t('gateway:Show whitelist settings'),
              isFreeAction: false,
              onClick: () => {
                setChosenWhitelistGateway(rowData);
              },
              disabled: !WHITELIST_MANUFACTURERS.has(rowData.manufacturer) || fp.get('is_backpack', rowData),
            }),
            (rowData) => ({
              icon: LocationCityIcon,
              tooltip: t('gateway:Automatically assign building'),
              isFreeAction: false,
              onClick: async () => {
                await autoassignGatewayBuilding({
                  variables: {
                    id: rowData.id,
                  },
                });
              },
              disabled: fp.get('building.id', rowData) || fp.get('is_backpack', rowData),
            }),
          ]}
        />
      </React.Fragment>
    );
  };