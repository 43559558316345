import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import LoadComponent from '../components/LoadComponent';
import TelegramSearch from './Dashboard/TelegramSearch';
import Status from './Dashboard/Status';
import DashboardItem from './Dashboard/DashboardItem';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Card from "./Dashboard/Card";

const GET_ORGANIZATION_STATS = gql`
    query getDashboardStats {
        organizationStats {
            gateways_count,
            last_six_months_gateways_count
            sensors_count
            wireless_sensors_count
            last_six_months_wireless_sensors_count
            buildings_count
            last_six_months_buildings_count
            apartments_count
            last_six_months_apartments_count
            building_coverage_count
        }
    }
`;

export const REPORTED_STATUS = {
	NO_ERROR: 0,
	F0: 1,
	F1: 10,
	F2: 20,
	F3A: 31,
	F3B: 32,
	F3C: 33,
	F4: 40,
	F5: 50,
	F6: 60,
	F7: 70,
	F8: 80,
	F9: 90,
	F10: 100,
	F11: 110
};

export const GATEWAY_STATUS = {
	F0A: 1,//STATUS_NO_CONNECTION
	F0B: 2,//STATUS_PERMANENT_ISSUE //Battery problems
};

export default function Dashboard() {
	const { data, loading, error } = useQuery(GET_ORGANIZATION_STATS);
	const { t } = useTranslation(["dashboard"]);
	const [isDownloadingGlobal, setIsDownloadingGlobal] = useState(false);

	if (error) {
		return <div>Error</div>;
	}
	const organizationStats = data?.organizationStats ?? {};

	return (
		<LoadComponent>
			<Grid container spacing={3}>
				<Status data={organizationStats} />
				<TelegramSearch />
				<Grid item xs={12} md={4}>
					<Card title={t("dashboard:Device Reception")}>
						<DashboardItem title={'F0: ' + t("dashboard:Gateway Error")} gatewayStatuses={[GATEWAY_STATUS.F0A, GATEWAY_STATUS.F0B]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F1: ' + t("dashboard:Never received a telegram")} reportedStatuses={[REPORTED_STATUS.F1]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F2: ' + t("dashboard:No Telegram Received longer than 18M - Dispo")} reportedStatuses={[REPORTED_STATUS.F2]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F3: ' + t("dashboard:No Telegram Received")} reportedStatuses={[REPORTED_STATUS.F3A, REPORTED_STATUS.F3B, REPORTED_STATUS.F3C]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F5: ' + t("dashboard:Measurement for Billing End Period missing")} reportedStatuses={[REPORTED_STATUS.F5]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card title={t("dashboard:Telegram Processing")}>
						<DashboardItem title={'F7: ' + t("dashboard:Duplicate serial number entered - Dispo")} reportedStatuses={[REPORTED_STATUS.F7]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F4: ' + t("dashboard:Telegram Decryption Problem")} reportedStatuses={[REPORTED_STATUS.F4]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F10: ' + t("dashboard:No telegram received - input error device master")} reportedStatuses={[REPORTED_STATUS.F10]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F11: ' + t("dashboard:Telegram could not be processed - Please contact support")} reportedStatuses={[REPORTED_STATUS.F11]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card title={t("dashboard:Device Errors")}>
						<DashboardItem title={'F6: ' + t("dashboard:Declining values measured - Dispo")} reportedStatuses={[REPORTED_STATUS.F6]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
						<Divider />
						<DashboardItem title={'F8: ' + t("dashboard:Device error - Dispo")} reportedStatuses={[REPORTED_STATUS.F8]} isDownloadingGlobal={isDownloadingGlobal} setIsDownloadingGlobal={setIsDownloadingGlobal} />
					</Card>
				</Grid>
			</Grid >
		</LoadComponent >
	);
}