import { Box, Button, CircularProgress, Typography, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import GatewayModel from "../../model/gateway";

// Utility function to validate numbers
function isValidNumber(value) {
    return typeof value === 'number' && Number.isFinite(value);
}

// GraphQL queries
const GET_REPORTED_STATUS_COUNT = gql`
    query GetReportedStatusCount($reportedStatuses: [Int!]!) {
        reportedStatusCount(reportedStatuses: $reportedStatuses)
    }
`;

const GET_GATEWAY_STATUS_COUNT = gql`
    query GetGatewayStatusCount($gatewayStatuses: [Int!]!) {
        gatewayStatusCount(gatewayStatuses: $gatewayStatuses)
    }
`;

const GET_ORGANIZATION_THRESHOLD = gql`
    query GetOrganizationThreshold {
        organizationThreshold {
            sensor_settings
            measurement_estimation_days
        }
    }
`;

const GET_GATEWAY_STATUS_EXCEL_DATA = gql`
    query GetGatewayStatusExcelData($gatewayStatuses: [Int!]!) {
        gatewayStatusExcelData(gatewayStatuses: $gatewayStatuses)
    }
`;
const GET_REPORTED_STATUS_EXCEL_DATA = gql`
    query GetReportedStatusExcelData($reportedStatuses: [Int!]!) {
        reportedStatusExcelData(reportedStatuses: $reportedStatuses)
    }
`;

const getErrorType = (reportedStatus, t, t1, t2) => {
    const errorDetails = {
        0: { code: "NO_ERROR", description: t("dashboard:No Error") },
        1: { code: "F0A", description: t("dashboard:Gateway is not sending data") },
        2: { code: "F0B", description: t("dashboard:Gateway battery is low") },
        10: { code: "F1", description: t("dashboard:Never received a telegram") },
        20: { code: "F2", description: t("dashboard:No Telegram Received longer than 18M - Dispo") },
        31: { code: "F3A", description: t("dashboard:No Telegram Received longer than {{T2}} - no UVI Possible", { T2: t2 }) },
        32: { code: "F3B", description: t("dashboard:No Telegram Received longer than {{T1}} - UVI Replacement Value", { T1: t1 }) },
        33: { code: "F3C", description: t("dashboard:No Telegram Received longer - No UVI in Building") },
        40: { code: "F4", description: t("dashboard:Telegram Decryption Problem") },
        50: { code: "F5", description: t("dashboard:Measurement for Billing End Period missing") },
        60: { code: "F6", description: t("dashboard:Declining values measured - Dispo") },
        70: { code: "F7", description: t("dashboard:Duplicate serial number entered - Dispo") },
        80: { code: "F8", description: t("dashboard:Device error - Dispo") },
        90: { code: "F9", description: t("dashboard:Some error description for F9") },
        100: { code: "F10", description: t("dashboard:No telegram received - input error device master") },
        110: { code: "F11", description: t("dashboard:Telegram could not be processed - Please contact support") },
    };

    return errorDetails[reportedStatus] || { code: "UNKNOWN", description: t("dashboard:Unknown error") };
};

const DashboardItem = ({ title, reportedStatuses, gatewayStatuses, isDownloadingGlobal, setIsDownloadingGlobal }) => {
    const { t } = useTranslation(["dashboard"]);

    const [isDownloading, setDownloadLoading] = useState(false);
    const client = useApolloClient();

    // Map 2 endpoints with the similar output
    const queryCount = gatewayStatuses ? GET_GATEWAY_STATUS_COUNT : GET_REPORTED_STATUS_COUNT;
    const queryExcel = gatewayStatuses ? GET_GATEWAY_STATUS_EXCEL_DATA : GET_REPORTED_STATUS_EXCEL_DATA;
    const statuses = gatewayStatuses ? gatewayStatuses : reportedStatuses;
    const statusParameter = gatewayStatuses ? 'gatewayStatuses' : 'reportedStatuses';
    const countDataParameter = gatewayStatuses ? 'gatewayStatusCount' : 'reportedStatusCount';
    const excelDataParameter = gatewayStatuses ? 'gatewayStatusExcelData' : 'reportedStatusExcelData';

    // Fetch count of reported statuses
    const { data, loading, error } = useQuery(queryCount, {
        variables: { [statusParameter]: statuses },
    });
    // Fetch organization threshold
    const { data: thresholdData, loading: thresholdLoading, error: thresholdError } = useQuery(GET_ORGANIZATION_THRESHOLD);

    // Handle threshold error
    if (thresholdError) {
        console.error("Error fetching organization threshold:", thresholdError);
    }

    const organizationThreshold = thresholdData ? thresholdData.organizationThreshold : undefined;

    // Handle error
    if (error) {
        console.error("Error fetching reported status count:", error);
    }

    const count = data ? data[countDataParameter] : undefined;

    const handleDownload = async () => {
        setDownloadLoading(true);
        setIsDownloadingGlobal(true);
        try {
            console.log("Fetching data for reportedStatuses:", statuses);

            const { data: excelData } = await client.query({
                query: queryExcel,
                variables: { [statusParameter]: statuses },
                fetchPolicy: "network-only", // Use network-only to always fetch fresh data
            });

            console.log("Fetched Excel data:", excelData);

            // Map through the data and add Error Type and Error Type Description
            const formattedData = excelData[excelDataParameter].map(item => {

                const errorType = item["Reported Status"] ? getErrorType(
                    item["Reported Status"], t, organizationThreshold.sensor_settings, organizationThreshold.measurement_estimation_days
                ) : null;
                const {
                    "Reported Status": _,
                    'Status': status,
                    'Device Status': deviceStatus,
                    'Import Method': method,
                    ...rest
                } = item;
                const methodLabel = GatewayModel.GATEWAY_IMPORT_METHOD_VALUES[method];
                const deviceStatusLabel = GatewayModel.GATEWAY_STATUS_VALUES[deviceStatus];
                const statusLabel = GatewayModel.GATEWAY_STAT_STATUS_VALUES[status];
                return {
                    ...(errorType ? {
                        'Error Type': errorType.code,
                        'Error Type Description': errorType.description
                    } : {}),
                    ...(gatewayStatuses ? {
                        'Status': statusLabel,
                    } : {}),
                    ...(gatewayStatuses ? {
                        'Import Method': methodLabel,
                    } : {}),
                    ...(gatewayStatuses ? {
                        'Device Status': deviceStatusLabel,
                    } : {}),
                    ...rest,
                };
            });

            const worksheet = XLSX.utils.json_to_sheet(formattedData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, `REPORTED-STATUS-${statuses.join('-')}`);
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            console.log("Generated Blob:", dataBlob);
            saveAs(dataBlob, `REPORTED-STATUS-${statuses.join('-')}-${new Date().toISOString()}.xlsx`);
        } catch (error) {
            console.error("Error during handleDownload:", error.message, error.networkError, error.graphQLErrors);
        } finally {
            setDownloadLoading(false);
            setIsDownloadingGlobal(false);
        }
    };


    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p={2}>
            {/* Title and count display */}
            <Box>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {t("dashboard:Number of Devices")}: {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        isValidNumber(count) ? count : t("dashboard:N/A")
                    )}
                </Typography>
            </Box>

            {/* Download button */}
            <Tooltip title={!isValidNumber(count) || count === 0 ? t("dashboard:noDataToDownload") : ""} arrow>
                <span>
                    <Button
                        variant="outlined"
                        color="primary"
                        endIcon={
                            isDownloading ? (
                                <Box display="flex" alignItems="center">
                                    <CircularProgress size={24} />
                                </Box>
                            ) : (
                                <DownloadIcon />
                            )
                        }
                        disabled={isDownloading || !isValidNumber(count) || count === 0 || isDownloadingGlobal}
                        onClick={handleDownload}
                    >
                        {isDownloading ? t("dashboard:loading") : t("dashboard:excel")}
                    </Button>
                </span>
            </Tooltip>
        </Box>
    );
};

export default DashboardItem;
