import React from 'react';
import moment from 'moment';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import useReactRouter from 'use-react-router';
import {Route} from 'react-router-dom';
import Receipt from '@material-ui/icons/Receipt';

import Table from '../components/Table';
import Organization from './Organization';
import { MenuItem, Select } from '@material-ui/core';

const GET_ORGANIZATIONS = gql`
  query getOrganizations($ids: [ID]) {
    organizations(ids: $ids) {
      id
      external_id
      name
      street
      house_number
      city
      postalcode
      phone
      email
      once_id
      updated_at
      threshold {
        sensor_settings
        gateway_settings
        gateway_reading_interval
        measurement_estimation_days
        prevent_duplicate_exports
      }
    }
  }
`;

const CREATE_ORGANIZATION = gql`
  mutation createOrganization(
    $name: String!
    $street: String
    $house_number: String
    $city: String
    $postalcode: String
    $phone: String
    $email: String
    $once_id: Int
    $sensor_settings: Int!
    $gateway_settings: Int!
    $gateway_reading_interval: String
    $measurement_estimation_days: Int!
    $prevent_duplicate_exports: Boolean!
  ) {
    createOrganization(
      name: $name
      street: $street
      house_number: $house_number
      city: $city
      postalcode: $postalcode
      phone: $phone
      email: $email
      once_id: $once_id
      sensor_settings: $sensor_settings
      gateway_settings: $gateway_settings
      gateway_reading_interval: $gateway_reading_interval
      measurement_estimation_days: $measurement_estimation_days
      prevent_duplicate_exports: $prevent_duplicate_exports
    ) {
      id
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $id: ID!
    $name: String!
    $street: String
    $house_number: String
    $city: String
    $postalcode: String
    $phone: String
    $email: String
    $once_id: Int
    $sensor_settings: Int!
    $gateway_settings: Int!
    $gateway_reading_interval: String
    $measurement_estimation_days: Int!
    $prevent_duplicate_exports: Boolean!
  ) {
    updateOrganization(
      id: $id
      name: $name
      street: $street
      house_number: $house_number
      city: $city
      postalcode: $postalcode
      phone: $phone
      email: $email
      once_id: $once_id
      sensor_settings: $sensor_settings
      gateway_settings: $gateway_settings
      gateway_reading_interval: $gateway_reading_interval
      measurement_estimation_days: $measurement_estimation_days
      prevent_duplicate_exports: $prevent_duplicate_exports
    ) {
      id
      name
      street
      house_number
      city
      postalcode
      phone
      email
      once_id
      threshold {
        sensor_settings
        gateway_settings
        gateway_reading_interval
        measurement_estimation_days
        prevent_duplicate_exports
      }
    }
  }
`;

const GATEWAL_READING_INTERVAL_VALUE_TO_LABEL_MAP = new Map([
  ['daily', 'Daily'],
  ['monthly', 'Once per month'],
  ['twice_monthly', 'Twice per month'],
]);
const GATEWAY_READING_INTERVAL_VALUE_DELIMITER = ',';

const Content = ({match}) => {
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION);
  const {t} = useTranslation(['organization']);
  const {loading, error, data} = useQuery(GET_ORGANIZATIONS, {});
  const [
    createOrganization,
    {loading: createOrganizationLoading, error: createOrganizationError},
  ] = useMutation(CREATE_ORGANIZATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORGANIZATIONS,
      },
    ],
  });
  const isLoading = loading || createOrganizationLoading;
  const {history} = useReactRouter();

  if (error || createOrganizationError) {
    return <div>Error</div>;
  }

  const getGatewayReadingIntervalLabel = (value, t) => {
    const label = GATEWAL_READING_INTERVAL_VALUE_TO_LABEL_MAP.get(value);
    return t(`organization:${label}`);
  }

  const columns = [
    {
      title: t('organization:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: false,
    },
    {title: t('organization:Name'), field: 'name'},
    {title: t('organization:Street'), field: 'street'},
    {title: t('organization:Number'), field: 'house_number'},
    {title: t('organization:City'), field: 'city'},
    {title: t('organization:ZIP code'), field: 'postalcode'},
    {title: t('organization:Phone'), field: 'phone'},
    {title: t('organization:E-mail'), field: 'email'},
    {title: t('organization:1NCE ID'), field: 'once_id'},
    {
      title: t('organization:SensorSetting'),
      field: 'threshold.sensor_settings',
    },
    {
      title: t('organization:GatewaySetting'),
      field: 'threshold.gateway_settings',
    },
    {
      title: t('organization:Gateway Reading Interval'),
      field: 'threshold.gateway_reading_interval',
      render: (rowData) => {
        const value = rowData.threshold?.gateway_reading_interval;
        return value ?
          value.split(GATEWAY_READING_INTERVAL_VALUE_DELIMITER)
            .map((v) => getGatewayReadingIntervalLabel(v, t)).join(', ')
          : '';
      },
      editComponent: (props) => (
        <Select
          multiple={true}
          value={typeof props.value === 'string'
            ? props.value.split(GATEWAY_READING_INTERVAL_VALUE_DELIMITER)
            : (props.value ?? [])}
          onChange={({ target: { value } }) => props.onChange(value)}
        >
          {Array.from(GATEWAL_READING_INTERVAL_VALUE_TO_LABEL_MAP.keys()).map((value, index) => (
            <MenuItem value={value} key={index}>{getGatewayReadingIntervalLabel(value, t)}</MenuItem>
          ))}
        </Select>
      )
    },
    {
      title: t('organization:Measurement Estimation Days'),
      field: 'threshold.measurement_estimation_days',
    },
    {
      title: t('organization:Prevent multiple exports'),
      field: 'threshold.prevent_duplicate_exports',
      type: 'boolean',
      filtering: false,
    },
    {
      title: t('organization:Update Date'),
      field: 'updated_at',
      render: (rowData) => {
        return rowData ? moment.utc(rowData.updated_at).format('LL') : '';
      },
      editable: false,
    },
  ];

  return (
    <React.Fragment>
      <Table
        tableId="organizations"
        title=""
        isLoading={isLoading}
        columns={columns}
        actions={[
          {
            icon: () => <Receipt />,
            tooltip: 'Billing',
            onClick: (_event, rowData) => {
              history.push(`${match.path}/${rowData.id}/billing`);
            },
          },
        ]}
        data={loading ? [] : data.organizations}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        onRowClick={(event, rowData) => {
          history.push(`${match.path}/${rowData.id}`);
        }}
        editable={{
          onRowAdd: async (newData) => {
            const gatewayReadingInterval = newData.threshold?.gateway_reading_interval;
            await createOrganization({
              variables: {
                name: newData.name,
                street: newData.street,
                house_number: newData.house_number,
                city: newData.city,
                postalcode: newData.postalcode,
                phone: newData.phone,
                email: newData.email,
                once_id: Number(newData['once_id']),
                sensor_settings: Number(newData.threshold?.sensor_settings) ? Number(newData.threshold.sensor_settings) : 31,
                gateway_settings: Number(newData.threshold?.gateway_settings) ? Number(newData.threshold.gateway_settings) : 30,
                gateway_reading_interval: typeof gatewayReadingInterval === 'string' ? gatewayReadingInterval : (gatewayReadingInterval?.join(',') || null),
                measurement_estimation_days: Number(newData.threshold?.measurement_estimation_days) ? Number(newData.threshold.measurement_estimation_days) : 62,
                prevent_duplicate_exports: !!newData.threshold?.prevent_duplicate_exports,
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            const gatewayReadingInterval = newData.threshold?.gateway_reading_interval;
            await updateOrganization({
              variables: {
                id: oldData.id,
                name: newData.name,
                street: newData.street,
                house_number: newData.house_number,
                city: newData.city,
                postalcode: newData.postalcode,
                phone: newData.phone,
                email: newData.email,
                once_id: Number(newData['once_id']),
                sensor_settings: Number(newData.threshold?.sensor_settings) ? Number(newData.threshold.sensor_settings) : 31,
                gateway_settings: Number(newData.threshold?.gateway_settings) ? Number(newData.threshold.gateway_settings) : 30,
                gateway_reading_interval: typeof gatewayReadingInterval === 'string' ? gatewayReadingInterval : (gatewayReadingInterval?.join(',') || null),
                measurement_estimation_days: Number(newData.threshold?.measurement_estimation_days) ? Number(newData.threshold.measurement_estimation_days) : 62,
                prevent_duplicate_exports: !!newData.threshold?.prevent_duplicate_exports,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default function Organizations({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/:id`} component={Organization} />
    </React.Fragment>
  );
}
