import { useMutation, useQuery } from '@apollo/react-hooks';
import { Box, Button, Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/styles';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table';
import downloadCsv from '../../lib/downloadCsv';
import BillingRateValueModel from '../../model/billing-rate-value';
import TextField from '@material-ui/core/TextField';
import { MTableToolbar } from 'material-table';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const { RATES } = BillingRateValueModel;

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 5
});

const GET_BILLING_INCLUSION_STATUS = gql`
  query getBillingInclusionStatus($organization_id: ID) {
    billingInclusionStatus(organization_id: $organization_id)
  }
`;

const UPDATE_BILLING_INCLUSION = gql`
  mutation updateBillingInclusion(
    $is_included: Boolean!
    $organization_id: ID!
  ) {
    updateBillingInclusion(
      is_included: $is_included
      organization_id: $organization_id
    ) 
  }
`;

const GET_BILLING_INVOICES = gql`
  query getBillingInvoices($organization_id: ID) {
    billingInvoices(organization_id: $organization_id) {
      id
      month
      bills {
        id
        rate
        units
        total
      }
      set_date
    }
  }
`;

const FREEZE_INVOICE = gql`
  mutation freezeInvoice(
    $month: Date!
    $set_date: Date!
    $organization_id: ID!
  ) {
    freezeInvoice(
      month: $month
      set_date: $set_date
      organization_id: $organization_id
    ) {
      id
    }
  }
`;

const CALCULATE_BILL = gql`
  mutation calculateBill(
    $organization_id: ID!
    $rate: BillingRate!
    $month: Date!
  ) {
    calculateBill(
      organization_id: $organization_id
      rate: $rate
      month: $month
    ) {
      rate
      total
      units
    }
  }
`;

const GET_BILLING_RATE_VALUES = gql`
  query getBillingRateValues($organization_id: ID!) {
    billingRateValues(organization_id: $organization_id) {
      id
      rate
      value
    }
  }
`;

const SET_RATE_VALUE = gql`
  mutation setRateValue(
    $rate: BillingRate!
    $value: Float
    $organization_id: ID!
  ) {
    setRateValue(
      rate: $rate
      value: $value
      organization_id: $organization_id
    ) {
      id
    }
  }
`;

const DELETE_INVOICE = gql`
  mutation deleteInvoice(
    $id: ID!
  ) {
    deleteInvoice(id: $id)
  }
`;

const isBillingInvoiceFrozen = (billingInvoice) => billingInvoice.id >= 0;

const BillingInvoicesTable = ({ organizationId }) => {
  const useStyles = makeStyles((theme) => ({
    tableToolbarWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    tableToolbar: {
      display: 'flex',
      flexGrow: 8,
    },
    newItemButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right'
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation(['billing']);
  const { loading: inclusionLoading, data: inclusionData } = useQuery(GET_BILLING_INCLUSION_STATUS, {
    variables: {
      organization_id: organizationId,
    }
  });
  const [updateInclusion] = useMutation(UPDATE_BILLING_INCLUSION);
  const { loading, error, data } = useQuery(GET_BILLING_INVOICES, {
    variables: {
      organization_id: organizationId,
    }
  });
  const { billingInvoices } = data || { billingInvoices: [] };

  const unconfirmedBillingInvoices = [];
  const billingInvoiceIdToBillingRateToBillMapMap = billingInvoices.reduce(
    (result, billingInvoice) => {
      const { bills } = billingInvoice;
      if (!bills) {
        unconfirmedBillingInvoices.push(billingInvoice);
        return result;
      }
      return {
        ...result,
        [billingInvoice.id]: billingInvoice.bills.reduce(
          (result, bill) => ({
            ...result,
            [bill.rate]: bill,
          }),
          {},
        ),
      };
    },
    {},
  );
  unconfirmedBillingInvoices.reverse();
  const [unconfirmedBillingInvoiceIdToBillingRateToBillMapMap, setUnconfirmedBillingInvoiceIdToBillingRateToBillMapMap] = useState({});
  const [calculateBill] = useMutation(CALCULATE_BILL);
  useEffect(() => {
    (async () => {
      for (const unconfirmedBillingInvoice of unconfirmedBillingInvoices) {
        const bills = await Promise.all(
          RATES.map(
            async (rate) => (await calculateBill({
              variables: {
                organization_id: organizationId,
                rate: rate.key,
                month: unconfirmedBillingInvoice.month,
              },
            })).data.calculateBill),
        );
        setUnconfirmedBillingInvoiceIdToBillingRateToBillMapMap((prevValue) => ({
          ...prevValue,
          [unconfirmedBillingInvoice.id]: bills.reduce(
            (result, bill) => ({
              ...result,
              [bill.rate]: bill,
            }),
            {},
          ),
        }));
      }
    })();
  }, [data]);

  const rows = billingInvoices.map((billingInvoice) => {
    let total = 0;
    const flatBills = RATES.reduce(
      (result, rate) => {
        const { id } = billingInvoice;
        const billingRateToBillMap = billingInvoiceIdToBillingRateToBillMapMap[id]
          || unconfirmedBillingInvoiceIdToBillingRateToBillMapMap[id]
          || {};
        const bill = billingRateToBillMap[rate.key];
        const billTotal = bill?.total;
        total += billTotal;
        return {
          ...result,
          [rate.totalField]: billTotal,
          [rate.unitsField]: bill?.units,
        };
      },
      {},
    );
    return {
      id: billingInvoice.id,
      month: billingInvoice.month,
      ...flatBills,
      total,
      set_date: billingInvoice.set_date,
    };
  });

  const [freezeInvoice, { loading: freezeInvoiceLoading, error: freezeInvoiceError }] = useMutation(
    FREEZE_INVOICE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_BILLING_INVOICES,
          variables: {
            organization_id: organizationId,
          },
        },
      ],
    },
  );
  const [deleteInvoice, { loading: deleteInvoiceLoading, error: deleteInvoiceError }] = useMutation(
    DELETE_INVOICE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_BILLING_INVOICES,
          variables: {
            organization_id: organizationId,
          },
        },
      ],
    },
  );

  const handleInclusionChange = async (isChecked) => {
    try {
      await updateInclusion({
        variables: {
          organization_id: organizationId,
          is_included: isChecked,
        },
        refetchQueries: [
          {
            query: GET_BILLING_INCLUSION_STATUS,
            variables: { organization_id: organizationId },
          },
        ],
      });
    } catch (error) {
      console.error('Error updating billing inclusion', error);
    }
  };

  const isLoading = loading || freezeInvoiceLoading || deleteInvoiceLoading;

  if (error || freezeInvoiceError || deleteInvoiceError) {
    return <div>Error</div>;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Table
      title={t('billing:Invoices')}
      tableId={`organization-${organizationId}-billing-invoices`}
      isLoading={isLoading}
      columns={[
        {
          title: t('billing:Month'),
          defaultSort: 'desc',
          field: 'month',
          render: (rowData) => moment.utc(rowData.month).format('MMMM YYYY'),
          editable: 'never',
        },
        ...RATES.map(({ name, totalField, unitsField }) => ({
          title: t(`billing:${name}`),
          field: totalField,
          render: (rowData) => {
            const rateTotal = rowData[totalField];
            if (rateTotal === undefined) {
              return '--';
            }
            if (totalField === 'fixed_price_rate_total') {
              return `${formatter.format(rateTotal)}`;
            }
            return rowData[unitsField];
          },
          editable: 'never',
        })),
        {
          title: t('billing:Total'),
          field: 'total',
          render: (rowData) => {
            const { total } = rowData;
            return !isNaN(total) ? formatter.format(total) : '--';
          },
          editable: 'never',
        },
        {
          title: t('billing:Date'),
          render: (rowData) => isBillingInvoiceFrozen(rowData)
            ? moment.utc(rowData.set_date).format('DD.MM.YYYY')
            : '',
          field: 'set_date',
          type: 'date',
        },
      ]}
      data={isLoading ? [] : rows}
      editable={{
        onRowUpdate: async ({ set_date }, { month }) => {
          if (!set_date) {
            return;
          }
          await freezeInvoice({
            variables: {
              month,
              set_date: moment.utc(set_date).format('YYYY-MM-DD'),
              organization_id: organizationId,
            }
          });
        },
        onRowDelete: async (oldData) => {
          await deleteInvoice({
            variables: { id: oldData.id },
          });
        },
        editTooltip: () => 'Set date',
        isDeleteHidden: (rowData) => !isBillingInvoiceFrozen(rowData),
        isEditHidden: (rowData) => isBillingInvoiceFrozen(rowData),
      }}
      options={{
        actionsColumnIndex: -1,
      }}
      actions={[
        {
          icon: () => <CloudDownload />,
          isFreeAction: true,
          tooltip: 'Download CSV',
          onClick: () => downloadCsv(
            {
              header: [
                'Month',
                ...BillingRateValueModel.RATE_NAMES,
                ...BillingRateValueModel.RATE_NAMES.map((field) => `${field} Totals`),
                'Total',
                'Set Date',
              ],
              rows: rows.map((row) => [
                row.month,
                ...BillingRateValueModel.RATE_UNITS_FIELDS.map((unitsField) => row[unitsField] ? row[unitsField] : '--'),
                ...BillingRateValueModel.RATE_TOTAL_FIELDS.map((rateField) => row[rateField] ? formatter.format(row[rateField]).replace('€', '') : '--'),
                row.total ? formatter.format(row.total).replace('€', '') : '--',
                row.set_date,
              ]),
            },
            'BillingInvoices_' + (organizationId ? organizationId + '_' : '') + new Date().toString() + '.csv',
          ),
        },
      ]}
      components={{
        Toolbar: (props) => (
          <div className={classes.tableToolbarWrapper}>
            <MTableToolbar {...props} classes={{ root: classes.tableToolbar }} />
            <Box mr={2} className={classes.newItemButtonWrapper}>
              {!inclusionLoading && inclusionData?.billingInclusionStatus ? (
                <Button
                  component="span"
                  color="secondary"
                  variant="outlined"
                  size="small"
                  startIcon={<RemoveCircleOutlineIcon />}
                  onClick={(event) => handleInclusionChange(false)}
                >
                  {t('billing:Exclude from billing report')}
                </Button>
              ) : (
                <Button
                  component="span"
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={(event) => handleInclusionChange(true)}
                >
                  {t('billing:Include in billing report')}
                </Button>
              )}
            </Box>
          </div>
        ),
      }}
    />
  );
}

const BillingRateValuesTable = ({ organizationId }) => {
  const { t } = useTranslation(['billing']);
  const { loading, error, data } = useQuery(GET_BILLING_RATE_VALUES, {
    variables: {
      organization_id: organizationId,
    }
  });
  const [setRateValue, { loading: setRateValueLoading, error: setRateValueError }] = useMutation(
    SET_RATE_VALUE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_BILLING_RATE_VALUES,
          variables: {
            organization_id: organizationId,
          },
        },
        {
          query: GET_BILLING_INVOICES,
          variables: {
            organization_id: organizationId,
          },
        },
      ],
    },
  );
  const isLoading = loading || setRateValueLoading;

  if (error || setRateValueError) {
    return <div>Error</div>;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const { billingRateValues } = data;
  const billingRateToValueMap = billingRateValues.reduce(
    (result, billingRateValue) => ({
      ...result,
      [billingRateValue.rate]: billingRateValue.value,
    }),
    {},
  );

  return (
    <Table
      title={t('billing:Rate Values')}
      tableId={`organization-${organizationId}-billing-rate-values`}
      isLoading={isLoading}
      options={{
        pageSizeOptions: [-1],
        pageSize: RATES.length,
      }}
      columns={[
        {
          title: t('billing:Rate'),
          field: 'rate',
          editable: 'never',
        },
        {
          title: t('billing:Description'),
          field: 'description',
          editable: 'never',
        },
        {
          title: t('billing:Price per item'),
          field: 'value',
          editComponent: (props) => (
            <TextField
              label={t('billing:Price per item')}
              variant='standard'
              defaultValue={props.value}
              InputProps={{
                inputProps: { min: 0 },
              }}
              onChange={({ target }) => {
                target.value = target.value < 0 ? 0 : target.value;
                props.onChange(target.value);
              }}
              onKeyDown={(e) => {
                const isKeyNotAllowed = isNaN(parseInt(e.key)) && e.key !== '.';
                if (!e.ctrlKey && e.key.length === 1 && isKeyNotAllowed) {
                  e.preventDefault();
                }
              }}
              type='decimal'
            />
          ),
          render: (rowData) => {
            const { value } = rowData;
            return value ? `${formatter.format(value)}` : '';
          },
        },
        {
          title: t('billing:Default price per item'),
          field: 'defaultValue',
          render: (rowData) => `${formatter.format(rowData.defaultValue)}`,
          editable: 'never',
        },
      ]}
      data={isLoading ? [] : RATES.map(({ name, key, description, defaultValue }) => ({
        rate: name,
        rate_key: key,
        description,
        value: billingRateToValueMap[key],
        defaultValue: defaultValue,
      }))}
      editable={{
        onRowUpdate: async ({ value }, { rate_key }) => {
          await setRateValue({
            variables: {
              rate: rate_key,
              value: Number(value),
              organization_id: organizationId,
            }
          });
        },
      }}
    />
  );
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function OrganizationBilling({ match }) {
  const classes = useStyles();
  const { id } = match.params;

  return (
    <div className={classes.root}>
      <Box>
        <BillingInvoicesTable organizationId={id} />
      </Box>
      {id &&
        <Box>
          <BillingRateValuesTable organizationId={id} />
        </Box>}
    </div>
  );
}